import React, { Component } from 'react';
import { Link as ScrollLink, scroller } from 'react-scroll';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';
import { languageOptions } from './../translations';

import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Button, MenuItem, } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MenuIcon from '@mui/icons-material/Menu';

/* TODO:THEME */
const MenuTypo = (props) => {
    return <Typography textAlign="center" style={{ color: "#303030" }} > {props.children}</Typography>;
}

/* TODO:THEME */
const MenuButton = (props) => {
    return <Button size="large" onClick={props.onClick} style={{ fontSize: "16pt", color: "#fff" }}>{props.children}</Button>;
}

export class NavMenu extends Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.handleScroll = this.handleScroll.bind(this);
        this.handleScrollTo = this.handleScrollTo.bind(this);

        this.state = {
            collapsed: true,
            navTransparent: true,
            path: null,
            home: true,
            anchorElNav: null,
            anchorElUser: null,
            loginLink: ""
        };

        fetch('api/Login/GetLink')
            .then(response => response.text())
            .then(data => {
                this.setState({ loginLink: data })
            });

        window.addEventListener('scroll', this.handleScroll);

        if (window.location.pathname === "/")
            this.state.home = true
        else
            this.state.home = false
    }

    handleScroll = (e) => {
        if (window.scrollY >= 80) {
            this.setState({ navTransparent: false, logoSize: "200px" });
        }
        else {
            this.setState({ navTransparent: true, logoSize: "400px" });
        }
    }

    handleScrollTo = (e, par) => {
        e.preventDefault();

        scroller.scrollTo(par, {
            smooth: true,
            duration: 700,
            spy: true
        })
    }

    handleLogEvent = (e, tar, act, info, url) => {
        e.preventDefault();

        fetch('api/EventLog/Create', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                target: tar,
                action: act,
                info: info
            })
        })

        if (url) {
            window.open(url, '_blank').focus();
        }
    }

    render() {
        const { userLanguage, userLanguageChange, dictionary } = this.context;
        const { anchorElNav, anchorElUser } = this.state;
        const handleLanguageChange = (event) => {
            this.setState({ anchorElUser: null });
            userLanguageChange((event.currentTarget.dataset || {}).lang)
        };

        const handleOpenNavMenu = (event) => {
            this.setState({ anchorElNav: event.currentTarget });
        };
        const handleOpenUserMenu = (event) => {
            this.setState({ anchorElUser: event.currentTarget });
        };

        const handleCloseNavMenu = () => {
            this.setState({ anchorElNav: null });
        };

        const handleCloseUserMenu = () => {
            this.setState({ anchorElUser: null });
        }

        const handleButtonScroll = (e, anchorName) => {
            this.setState({ anchorElNav: null });
            this.handleScrollTo(e, anchorName);
        }
        return (
            <React.Fragment>
                <AppBar color={this.state.navTransparent ? "primary" : "primary"} elevation={this.state.navTransparent ? 0 : 3}>
                    <Container maxWidth="xl" style={{ padding: 0 }}>
                        <Toolbar disableGutters>
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <Accordion style={{ backgroundColor: "#800000", width: "100%" }}>
                                    <AccordionSummary expandIcon={<MenuIcon style={{ fill: '#fff' }} />} aria-controls="panel1a-content" id="panel1a-header">
                                        <img alt="logo" src={"/images/logo/logo_text.png"} id="logo" onClick={(e) => handleButtonScroll(e, "Home")} style={{ cursor: "pointer", height: "65px" }} />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <MenuItem onClick={(e) => { handleButtonScroll(e, "Features"); this.handleLogEvent(e, "Features", "Click", "Navmenu") }} style={{ fontSize: "20px", color: "#fff" }}>
                                            {dictionary.NavMenu.Features}
                                        </MenuItem>
                                        <MenuItem onClick={(e) => { handleButtonScroll(e, "Prices"); this.handleLogEvent(e, "Prices", "Click", "Navmenu") }} style={{ fontSize: "20px", color: "#fff" }}>
                                            {dictionary.NavMenu.Prices}
                                        </MenuItem>
                                        <MenuItem onClick={(e) => { handleButtonScroll(e, "Contact"); this.handleLogEvent(e, "Support", "Click", "Navmenu") }} style={{ fontSize: "20px", color: "#fff" }}>
                                            {dictionary.NavMenu.Support}
                                        </MenuItem>
                                        <MenuItem>
                                            <Button color="primary" component={Link} to={this.state.loginLink} variant="contained" style={{ fontSize: "20px", margin: "0px 5px" }}>{dictionary.Home.Login}</Button>
                                        </MenuItem>
                                        <MenuItem>
                                            <Button component={Link} onClick={(e) => handleButtonScroll(e, "NewUser")} color="text" variant="outlined" style={{ fontSize: "20px", margin: "0px 5px" }}>{dictionary.Home.SignUp}</Button>
                                        </MenuItem>

                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} >
                                <img src={"/images/logo/logo_text.png"} id="logo" onClick={(e) => handleButtonScroll(e, "Home")} style={{ cursor: "pointer", height: "90px" }} />
                            </Box>
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                {/*                            <MenuButton onClick={(e) => { handleButtonScroll(e, "Benefits"); this.handleLogEvent(e, "Benefits", "Click", "Navmenu") }}>{dictionary.NavMenu.Benefits}</MenuButton>*/}
                                <MenuButton onClick={(e) => { handleButtonScroll(e, "Features"); this.handleLogEvent(e, "Features", "Click", "Navmenu") }}>{dictionary.NavMenu.Features}</MenuButton>
                                <MenuButton onClick={(e) => { handleButtonScroll(e, "Prices"); this.handleLogEvent(e, "Prices", "Click", "Navmenu") }}>{dictionary.NavMenu.Prices}</MenuButton>
                                {/*<Link style={{ textDecoration: 'none' }} onClick={(e) => this.handleLogEvent(e, "Blog", "Click", "Navmenu", "/blog_" + userLanguage)}><MenuButton>{dictionary.NavMenu.Help}</MenuButton></Link>*/}
                                <MenuButton onClick={(e) => { handleButtonScroll(e, "Contact"); this.handleLogEvent(e, "Support", "Click", "Navmenu") }}>{dictionary.NavMenu.Support}</MenuButton>
                            </Box>

                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                <Button component={Link} to={this.state.loginLink} color="primary" variant="contained" style={{ fontSize: "16pt", margin: "0px 5px" }} >{dictionary.Home.Login}</Button>
                                <Button component={Link} onClick={(e) => handleButtonScroll(e, "NewUser")} color="text" variant="outlined" style={{ fontSize: "16pt", margin: "0px 5px" }}>{dictionary.Home.SignUp}</Button>
                            </Box>

                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                <MenuButton onClick={handleOpenUserMenu}>{dictionary.displayName}</MenuButton>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
                <React.Fragment>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}

                    >
                        {
                            Object.entries(languageOptions).map(([id, name]) => (
                                <MenuItem key={id} data-lang={id} onClick={(e) => { handleLanguageChange(e); this.handleLogEvent(e, id, "Kielen vaihto", "Navmenu") }} style={{ fontSize: "20px", color: "#fff" }}>
                                    <MenuTypo>{name}</MenuTypo>
                                </MenuItem>
                            ))
                        }
                    </Menu>
                </React.Fragment>
            </React.Fragment>
        );
    }
}
